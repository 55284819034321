import React from 'react';
import { Form, Row, Col, Button, Card, InputNumber, TimePicker } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import { Rule } from 'antd/lib/form';
import moment from 'moment';

const breastCompositionOptions = [
    { value: 'Entirely Fatty', label: 'Entirely Fatty' },
    { value: 'Scattered Fibroglandular', label: 'Scattered Fibroglandular' },
    { value: 'Heterogeneously Dense', label: 'Heterogeneously Dense' },
    { value: 'Extremely Dense', label: 'Extremely Dense' },
];

const biradsOptions = [
    { value: 'None', label: 'None' },
    { value: '0', label: '0' },
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '4a', label: '4a' },
    { value: '4b', label: '4b' },
    { value: '4c', label: '4c' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
];

const calcificationMorphologyOptions = [
    { value: 'Amorphous', label: 'Amorphous' },
    { value: 'Coarse Heterogeneous', label: 'Coarse Heterogeneous' },
    { value: 'Fine Pleomorphic', label: 'Fine Pleomorphic' },
    { value: 'Fine Linear', label: 'Fine Linear' },
    { value: 'None', label: 'None' },
    { value: 'Skin', label: 'Skin' },
    { value: 'Vascular', label: 'Vascular' },
    { value: 'Coarse', label: 'Coarse' },
    { value: 'Large rod-like', label: 'Large rod-like' },
    { value: 'Round', label: 'Round' },
    { value: 'Rim', label: 'Rim' },
    { value: 'Dystrophic', label: 'Dystrophic' },
    { value: 'Milk of Calcium', label: 'Milk of Calcium' },
    { value: 'Suture', label: 'Suture' },
];

const calcificationDistributionOptions = [
    { value: 'None', label: 'None' },
    { value: 'Diffuse', label: 'Diffuse' },
    { value: 'Regional', label: 'Regional' },
    { value: 'Grouped', label: 'Grouped' },
    { value: 'Segmental', label: 'Segmental' },
    { value: 'Linear', label: 'Linear' },
];

const asymmetryOptions = [
    { value: 'Asymmetry', label: 'Asymmetry' },
    { value: 'Global Asymmetry', label: 'Global Asymmetry' },
    { value: 'Focal Asymmetry', label: 'Focal Asymmetry' },
    { value: 'Developing Asymmetry', label: 'Developing Asymmetry' },
];

const lymphNodeLocationOptions = [
    { value: 'Intramammary Benign', label: 'Intramammary Benign' },
    { value: 'Intramammary Suspicious', label: 'Intramammary Suspicious' },
    { value: 'Axillary Suspicious', label: 'Axillary Suspicious' },
    { value: 'Axillary Benign', label: 'Axillary Benign' },
];

interface FormField {
    label: string;
    nameSuffix: string;
    Component: React.ComponentType<any>;
    placeholder?: string;
    items?: Array<{ value: string; label: string }>;
    rules?: Rule[];
}

interface DynamicFormListProps {
    formFields: FormField[];
    namePrefix: string;
    addButtonLabel: string;
    initialData?: any; // New prop for initial data
}

const { Option } = Select;

interface SelectComponentProps {
    items: Array<{ value: string; label: string }>;
    placeholder?: string;
}

interface BreastDetails {
    lymphNodes?: { location: string; type: string }[];
    breastComposition: string;
    calcifications?: { location?: string; size: number; morphology: string; distribution: string }[]; // Array of calcification objects
    masses?: { location?: string; size: number }[];
    asymmetry?: { location: string; type: string }[];
    birads: string;
}

interface BreastExam {
    leftBreast: BreastDetails;
    rightBreast: BreastDetails;
}
interface MammogramFormProps {
    formData: BreastExam;
    sendFormData(data: BreastExam, setSubmitState: CallableFunction): Promise<any>; // todo
}

const SelectComponent: React.FC<SelectComponentProps> = ({ items, placeholder, ...props }) => (
    <Select placeholder={placeholder} {...props}>
        {items.map((item) => (
            <Option key={item.value} value={item.value}>
                {item.label}
            </Option>
        ))}
    </Select>
);

const DynamicFormList: React.FC<DynamicFormListProps> = ({ formFields, namePrefix, addButtonLabel, initialData }) => (
    <Form.List name={namePrefix} initialValue={initialData}>
        {(fields, { add, remove }) => (
            <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <span style={{ width: '100%' }} key={key}>
                        <Row gutter={[16, 16]}>
                            {formFields.map(({ label, nameSuffix, Component, rules = [], ...fieldProps }, index) => (
                                <Col span={6} key={index}>
                                    <Form.Item
                                        {...restField}
                                        name={[name, nameSuffix]}
                                        fieldKey={[fieldKey, nameSuffix]}
                                        label={label}
                                        rules={rules}
                                    >
                                        <Component {...fieldProps} />
                                    </Form.Item>
                                </Col>
                            ))}
                            <MinusCircleOutlined onClick={() => remove(name)} />
                        </Row>
                    </span>
                ))}
                <Form.Item>
                    <Button type='dashed' onClick={() => add()} icon={<PlusOutlined />}>
                        {addButtonLabel}
                    </Button>
                </Form.Item>
            </>
        )}
    </Form.List>
);
const FormTimePicker: React.FC = ({ value, onChange }) => {
    const possibleFormats = ['h:mm A', 'HH:mm', 'h:mm'];
    const parsedTime = value ? moment(value, possibleFormats, true) : null;
    return (
        <TimePicker
            use12Hours
            minuteStep={10}
            showSecond={false}
            showNow={false}
            format={'HH:mm'}
            value={parsedTime}
            onChange={onChange}
        />
    );
};

const BreastForm: React.FC<{ namePrefix: string; initialData: any }> = ({ namePrefix, initialData }) => {
    const validateSize = (rule: Rule, value: number) => {
        if (!value || value > 0) {
            return Promise.resolve();
        }
        return Promise.reject(new Error('Size must be a positive number greater than 0'));
    };

    return (
        <>
            <Form.Item label='Breast Composition' name={[namePrefix, 'breastComposition']} rules={[{ required: true }]}>
                <SelectComponent items={breastCompositionOptions} placeholder='Select Breast Composition' />
            </Form.Item>

            <Card title='Lymph Nodes' bordered={false}>
                <DynamicFormList
                    formFields={[
                        {
                            label: "Location(o'clock)",
                            nameSuffix: 'location',
                            Component: FormTimePicker, // Still using Input for text field
                            rules: [{ required: false }],
                        },
                        {
                            label: 'Size(mm)',
                            nameSuffix: 'size',
                            Component: InputNumber, // Still using Input for text field
                            placeholder: 'e.g., 2 mm',
                            rules: [{ required: false }],
                        },
                        {
                            label: 'Type',
                            nameSuffix: 'type',
                            Component: SelectComponent,
                            items: lymphNodeLocationOptions,
                            rules: [{ required: true, message: 'Type is required' }],
                        },
                    ]}
                    namePrefix={`${namePrefix}.lymphNodes`}
                    addButtonLabel='Add Lymph Node'
                    initialData={initialData.lymphNodes}
                />
            </Card>

            <Card title='Calcifications' bordered={false}>
                <DynamicFormList
                    formFields={[
                        {
                            label: "Location(o'clock)",
                            nameSuffix: 'location',
                            Component: FormTimePicker, // Location remains text
                            rules: [{ required: false, message: 'Location is required' }],
                        },
                        {
                            label: 'Size(mm)',
                            nameSuffix: 'size',
                            Component: InputNumber, // Use InputNumber for size
                            placeholder: 'e.g., 10 mm',
                            rules: [{ required: false }, { validator: validateSize }],
                        },
                        {
                            label: 'Morphology',
                            nameSuffix: 'morphology',
                            Component: SelectComponent,
                            items: calcificationMorphologyOptions,
                            rules: [{ required: true, message: 'Morphology is required' }],
                        },
                        {
                            label: 'Distribution',
                            nameSuffix: 'distribution',
                            Component: SelectComponent,
                            items: calcificationDistributionOptions,
                            rules: [{ required: true, message: 'Distribution is required' }],
                        },
                    ]}
                    namePrefix={`${namePrefix}.calcifications`}
                    addButtonLabel='Add Calcification'
                    initialData={initialData.calcifications}
                />
            </Card>

            <Card title='Masses' bordered={false}>
                <DynamicFormList
                    formFields={[
                        {
                            label: "Location(o'clock)",
                            nameSuffix: 'location',
                            Component: FormTimePicker, // Location remains text
                            rules: [{ required: true, message: 'Location is required' }],
                        },
                        {
                            label: 'Size(mm)',
                            nameSuffix: 'size',
                            Component: InputNumber, // Use InputNumber for size
                            placeholder: 'e.g., 10 mm',
                            rules: [{ required: true, message: 'Size is required' }, { validator: validateSize }],
                        },
                    ]}
                    namePrefix={`${namePrefix}.masses`}
                    addButtonLabel='Add Mass'
                    initialData={initialData.masses}
                />
            </Card>

            <Card title='Asymmetry' bordered={false} style={{ margin: '10px 0 10px 0' }}>
                <DynamicFormList
                    formFields={[
                        {
                            label: "Location(o'clock)",
                            nameSuffix: 'location',
                            Component: FormTimePicker,
                            rules: [{ required: true, message: 'Location is required' }],
                        },
                        {
                            label: 'Type',
                            nameSuffix: 'type',
                            Component: SelectComponent,
                            items: asymmetryOptions,
                            rules: [{ required: true, message: 'Type is required' }],
                        },
                    ]}
                    namePrefix={`${namePrefix}.asymmetry`}
                    addButtonLabel='Add Asymmetry'
                    initialData={initialData.asymmetry}
                />
            </Card>

            <Form.Item label='BIRADS' name={[namePrefix, 'birads']} rules={[{ required: true }]}>
                <SelectComponent items={biradsOptions} placeholder='Select BIRADS' />
            </Form.Item>
        </>
    );
};

const MammogramForm = (props: MammogramFormProps) => {
    const [form] = Form.useForm();
    const { formData, sendFormData } = props;
    const [sampleData, setSampleData] = React.useState<BreastExam | null>(formData);
    const [isSubmiting, setSending] = React.useState<boolean>(false);

    const onFinish = async (values: any) => {
        console.log('Form Values:', values);
        await sendFormData(formData, setSending);
    };

    // Fetch form data and set default values
    React.useEffect(() => {
        setSampleData(formData);
        form.setFieldsValue(formData);
    });

    return (
        <Form form={form} layout='vertical' onFinish={onFinish} style={{ padding: '1rem' }}>
            <h2>Left Breast</h2>
            {sampleData && <BreastForm namePrefix='leftBreast' initialData={sampleData.leftBreast} />}

            <br />
            <br />

            <h2>Right Breast</h2>
            {sampleData && <BreastForm namePrefix='rightBreast' initialData={sampleData.rightBreast} />}

            <Form.Item>
                <Button type='primary' htmlType='submit' loading={isSubmiting}>
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};

export default MammogramForm;
