/* This module wraps all the logic related to the medical imaging */
import config from 'config';
import ObjectState from 'cvat-core/src/object-state';
import { Point, findLargestDiameter, numberArrayToPoints } from 'utils/math';
import { getRealSize } from 'utils/misc';
import { Canvas } from 'cvat-canvas-wrapper';

abstract class MedicalState {
    public state: ObjectState;

    constructor(state: ObjectState) {
        this.state = state;
    }
}

export class Calcification extends MedicalState {
    public diameter: number;
    public readonly isDystrophic: boolean;
    static readonly dystrophyMinSize = config.MEDICAL.DYSTROPHIC_CALCIFICATION_MIN_SIZE;
    static readonly dystrophyLabelName = config.MEDICAL.DYSTROPHIC_CALCIFICATION_LABEL_NAME;
    // NOTE labelNameInitails must become lowercased
    static readonly labelNameInitails = config.MEDICAL.CALCIFICATION_LABEL_INITIAL.toLowerCase();
    public points: Point[];

    constructor(state: ObjectState, canvas: Canvas) {
        super(state);

        this.checkCalcification();
        this.points = numberArrayToPoints(state.points as number[]);
        const points = numberArrayToPoints(state.points as number[]);
        const largestDiameter = findLargestDiameter(points);
        this.diameter = getRealSize(largestDiameter, canvas);
        this.isDystrophic = this.diameter > Calcification.dystrophyMinSize;
    }

    static isCalcification(objectState: ObjectState) {
        const lowerCasedLabelName = objectState.label.name.toLocaleLowerCase();
        return lowerCasedLabelName.startsWith(Calcification.labelNameInitails);
    }

    private checkCalcification() {
        if (!Calcification.isCalcification(this.state)) {
            throw new Error('This objectState is not a calcification.');
        }
    }
}
