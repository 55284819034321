// Copyright (C) 2022 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import { Label, ObjectState, LabelType } from 'cvat-core-wrapper';

interface NamedObject {
    name: string;
    [key: string]: any
}

export function filterApplicableForType(type: LabelType, labels: Label[]): Label[] {
    const applicableLabels = labels.filter((label: Label) => [type, 'any'].includes(label.type));

    return applicableLabels;
}

export function filterApplicableLabels(objectState: ObjectState, labels: Label[]): Label[] {
    const applicableLabels = filterApplicableForType((objectState.shapeType || 'tag') as unknown as LabelType, labels);

    // a label the object has at this moment considered like applicable label
    if (!applicableLabels.find((label: Label) => label.id === objectState.label.id)) {
        return [objectState.label, ...applicableLabels];
    }

    return applicableLabels;
}

export const filterByName = (list: any[], name: string): any =>
    list.filter((i: any) => i.name.toLocaleLowerCase() === name.toLocaleLowerCase());

export function filterByLabelName(objectStates: ObjectState[], labelName: string): ObjectState[] {
    return objectStates.filter((i: ObjectState) => i.label.name.toLocaleLowerCase() === labelName.toLocaleLowerCase());
}

export function setLabelByName(objectState: ObjectState, name: string, cvatState: any): ObjectState {
    const { labels } = cvatState.annotation.job;
    const label = filterByName(labels, name)[0];
    if (!label) {
        console.error(`Label with name "${name}" not found. Autolabeling does not work.`)
        return objectState
    }
    objectState.label = label;
    return objectState
}
